<template>
  <div class="home" id="beranda">
    <div class="hero bg-primary-light pt-8 flex flex-col items-stretc relative mdmax:pt-16">
      <div class="absolute left-0 top-0 mdmax:z-0 mdmax:-left-12">
        <img src="~@/assets/images/ellipse2.png" class="relative z-1" />
        <img src="~@/assets/images/ellipse1.png" class="-mt-20 relative z-0" />
      </div>
      <TheHeader v-if="!isMobile" />
      <TheMobileHeader v-else ref="headerMobile" />
      <div class="container flex-1 flex justify-between items-center gap-16 mdmax:flex-none mdmax:gap-6 mdmax:flex-col mdmax:relative mdmax:z-1 mdmax:px-5 mdmax:mt-6">
        <div class="flex-1 mdmax:flex-none reveal reveal-top">
          <h1 class="text-5xl font-bold leading-tight mdmax:text-3xl">Tes Psikologi SIM Online dengan EDU TEST</h1>
          <p class="mt-4 text-text-secondary mdmax:font-medium">Sekarang Tes Psikologi untuk persyaratan pembuatan SIM Baru / Perpanjangan jadi lebih praktis dengan EDU TEST.</p>
          <div class="w-48 mt-6">
            <LinkButton buttonText="Mulai Sekarang" linkType="external" url="https://psikotes.edu-test.id/login" />
          </div>
        </div>
        <div class="flex-shrink-0 flex-1 flex justify-center items-center mdmax:flex-none mdmax:pb-6 reveal" :class="[isMobile ? 'reveal-top' : 'reveal-right']">
          <img src="~@/assets/images/illustration1.png" />
        </div>
      </div>
    </div>
    <div class="bg-white py-28 mdmax:px-5 mdmax:py-16" id="fitur">
      <div class="container">
        <p class="text-center text-4xl font-bold mb-3 mdmax:text-3xl">Kenapa Tes Psikologi SIM EDU TEST</p>
        <p class="text-center text-text-secondary max-w-xl mx-auto">
          EDU TEST adalah platform Tes Psikologi SIM resmi yang diakreditasi Biro Psikologi SSDM POLRI dan terintegrasi dengan aplikasi SINAR Korlantas POLRI.
        </p>
        <div class="grid grid-cols-3 gap-8 mt-24 mdmax:mt-10 mdmax:grid-cols-1 reveal reveal-top" v-if="!isMobile">
          <div class="shadow-card rounded-md py-8 px-12 flex justify-start items-center flex-col mdmax:py-6 mdmax:px-6" v-for="(uspItem, index) in usp" :key="'usp-' + index">
            <div class="w-16 h-16 rounded-full shadow-round flex justify-center items-center mb-6">
              <component :is="uspItem.icon" color="primary" />
            </div>
            <p class="text-lg font-bold mb-6">{{ uspItem.title }}</p>
            <p class="text-text-secondary text-center">{{ uspItem.description }}</p>
          </div>
        </div>
        <div v-else class="mt-6">
          <carousel :options="carouselBannerOptions" :slides="usp">
            <template slot="content" slot-scope="{ item }">
              <div class="p-1">
                <div class="border border-primary rounded-md py-6 px-6 flex justify-start items-center flex-col">
                  <div class="w-16 h-16 rounded-full shadow-round flex justify-center items-center mb-6">
                    <component :is="item.icon" color="primary" />
                  </div>
                  <p class="text-lg font-bold mb-6">{{ item.title }}</p>
                  <p class="text-text-secondary text-center h-24">{{ item.description }}</p>
                </div>
              </div>
            </template>
          </carousel>
        </div>
      </div>
    </div>
    <div class="bg-white py-20 mdmax:px-5 mdmax:py-16">
      <div class="container flex justify-center gap-10 items-center mdmax:flex-col mdmax:gap-6">
        <div class="flex-1 flex justify-start mdmax:order-2 mdmax:w-72 mdmax:mx-auto">
          <img src="~@/assets/images/illustration2.png" />
        </div>
        <div class="flex-1 mdmax:order-1">
          <p class="text-4xl font-bold mb-3 mdmax:text-3xl mdmax:text-center">Registrasi sangat mudah</p>
          <p class="text-text-secondary mdmax:text-center">EDU TEST registrasi cukup mudah dengan hanya 3 langkah, anda sudah bisa terdaftar dan bisa melakukan pisikologi test secara online.</p>
        </div>
      </div>
    </div>
    <div class="bg-white py-20 mb-20 mdmax:px-5 mdmax:py-16">
      <div class="container flex justify-center gap-10 items-center mdmax:flex-col mdmax:gap-6">
        <div class="flex-1">
          <p class="text-4xl font-bold mb-3 mdmax:text-3xl mdmax:text-center">Pilih booking test</p>
          <p class="text-text-secondary mdmax:text-center">Pilih lokasi test terdekat Anda dna tentukan tanggal dan waktu pelaksanaan testnya sesuai dengan keinginan Anda.</p>
        </div>
        <div class="flex-1 justify-end flex mdmax:w-72 mdmax:mx-auto">
          <img src="~@/assets/images/illustration3.png" />
        </div>
      </div>
    </div>
    <div class="bg-secondary py-16 mdmax:px-5 mdmax:py-16">
      <div class="container">
        <p class="text-center text-4xl font-bold mb-20 mdmax:text-3xl">Proses Psikotes di EDU TEST</p>
        <div class="grid grid-cols-4 gap-8 mdmax:grid-cols-1">
          <div class="flex flex-col justify-start items-center mdmax:mb-4 reveal reveal-top" :class="'delay-' + index" v-for="(processItem, index) in process" :key="'process-' + index">
            <component :is="processItem.icon" color="primary" />
            <p class="mt-8 text-sm text-text-secondary mb-1 mdmax:mt-4">Langkah {{ index + 1 }}</p>
            <p class="text-lg font-bold mb-6">{{ processItem.title }}</p>
            <p class="text-sm text-center">{{ processItem.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-light py-16 mdmax:px-5 mdmax:py-16">
      <div class="container flex justify-between items-center mdmax:flex-col">
        <div class="mdmax:mb-4">
          <p class="text-lg">Register akun</p>
          <p class="text-4xl font-bold mdmax:text-3xl">Cukup 3 menit saja.</p>
        </div>
        <LinkButton buttonText="Registrasi Sekarang" linkType="external" url="https://psikotes.edu-test.id/login" />
      </div>
    </div>
    <div class="bg-white pb-20 pt-32 mdmax:px-5 mdmax:py-16">
      <div class="container flex justify-center gap-10 items-center mdmax:flex-col">
        <div class="flex-1">
          <p class="text-4xl font-bold mb-3 mdmax:text-3xl mdmax:text-center">Mitra Resmi Korlantas POLRI</p>
          <p class="text-text-secondary mdmax:text-center">
            Kami merupakan mitra resmi korlantas POLRI dalam penyelenggaraan test psikologi. Jangan ragu lagi untuk melaksanakan test psikologi untuk perpanjangan dan pembuatan SIM melalui kami.
          </p>
        </div>
        <div class="flex-1 flex justify-end mdmax:w-72 mdmax:mx-auto">
          <img src="~@/assets/images/illustration4.png" />
        </div>
      </div>
    </div>
    <div class="bg-white py-20 mdmax:px-5 mdmax:py-16">
      <div class="container flex justify-center gap-10 items-center mdmax:flex-col">
        <div class="flex-1 flex justify-start mdmax:order-2 mdmax:w-72 mdmax:mx-auto">
          <img src="~@/assets/images/illustration5.png" />
        </div>
        <div class="flex-1 mdmax:order-1">
          <p class="text-4xl font-bold mb-3 mdmax:text-3xl mdmax:text-center">Data aman terjamin</p>
          <p class="text-text-secondary mdmax:text-center">Data anda terjamin dan tidak akan kami salah gunakan.</p>
        </div>
      </div>
    </div>
    <!-- <div class="bg-white py-20 mdmax:px-5 mdmax:py-16" id="biaya">
      <div class="container flex justify-center gap-10 items-center mdmax:flex-col">
        <div class="flex-1">
          <p class="text-4xl font-bold mb-3 mdmax:text-3xl mdmax:text-center">Harga Test</p>
          <p class="text-text-secondary mb-6">
            Biaya tes psikologi SIM di EDU TEST adalah yang paling murah di antara para penyedia platform tes psikologi online, dengan masa berlaku hasil tes selama 6 (enam) bulan.
          </p>
          <div class="flex gap-8 mb-6" v-for="i in 3" :key="i">
            <span><DoubleQuotes /></span>
            <div class="">
              <p class="text-sm mb-6">
                Nunc auctor odio non nisl consequat ornare. Duis volutpat nibh vitae dui convallis, ut condimentum neque ornare. Nulla facilisi. Nam convallis mi arcu, in auctor ligula sodales eu.
              </p>
              <div class="flex items-center gap-6">
                <img src="~@/assets/images/photo-sample.png" />
                <p class="font-medium text-sm">Nilcolas Hutapea</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="max-w-sm mx-auto p-9 bg-primary text-center flex flex-col items-center rounded-md">
            <img src="~@/assets/images/logo/edutest.png" />
            <p class="text-white font-bold my-4">EDU TEST</p>
            <p class="text-white text-4xl font-bold mb-9">Rp20.000</p>
            <p class="text-sm mb-6 text-white">Tes Psikologi SIM resmi yang terkoneksi dengan aplikasi Korlantas</p>
            <p class="text-sm mb-6 text-white">Masa berlaku 6 (enam) bulan</p>
            <p class="text-sm mb-6 text-white">1 tes berlaku untuk berbagai golongan SIM</p>
            <p class="text-sm mb-6 text-white">Termasuk layanan konseling**</p>
            <p class="text-sm text-white mb-16">Dapat digunakan untuk proses Online dan Offline</p>
            <LinkButton buttonText="Mulai Test" type="secondary" linkType="internal" url="/" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="bg-white pt-28 mdmax:px-5 mdmax:py-16" id="faq">
      <div class="container">
        <div class="flex items-center justify-center flex-col gap-8 mb-6 mdmax:flex-col mdmax:gap-6">
          <img src="~@/assets/images/logo/edutest.png" />
          <p class="text-2xl font-bold">Hubungi Edu Test</p>
          <!-- <p class="mdmax:text-center">Silakan kirimkan pertanyaan dan pengaduan Anda kepada Kami melalui:</p>
          <div class="flex-1 flex gap-6 justify-end mdmax:flex-col mdmax:flex-none mdmax:w-full mdmax:gap-4">
            <div class="relative flex-1">
              <span class="inline-block absolute top-1/2 left-3 transform -translate-y-1/2">
                <Envelop color="primary" />
              </span>
              <TextField withIcon placeholder="Masukan email anda" />
            </div>
            <LinkButton buttonText="Submit" linkType="internal" url="/" />
          </div> -->
          <div class="flex gap-6 items-center mdmax:flex-col">
            <div class="flex items-center gap-2"><Whatsapp width="24" height="24" color="#fff" /><span class="font-bold">Hotline:</span></div>
            <ul class="text-sm mdmax:text-center flex items-center gap-2">
              <li class="text-neutral-500"><a href="https://wa.me/08216500321" class="text-primary" target="_blank">+628216500321</a></li>
              <li class="text-neutral-500"><a href="https://wa.me/087832142027" class="text-primary" target="_blank">+6287832142027</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white pt-10 pb-6 text-center text-xs">
      <p class="mb-1">Copyright © EDUTEST 2022.</p>
      <p>PT Solusi Teknologi Maju</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { scrollToTheTarget } from '@/utils'
export default {
  name: 'Home',
  components: {
    TheHeader: () => import(/* webpackChunkName: "TheHeader" */ '@/containers/TheHeader'),
    TheMobileHeader: () => import(/* webpackChunkName: "TheHeader" */ '@/containers/TheMobileHeader'),
    LinkButton: () => import(/* webpackChunkName: "LinkButton" */ '@/components/Form/LinkButton'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    DoubleQuotes: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/DoubleQuotes'),
    Logo: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Logo'),
    Phone: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Phone'),
    Whatsapp: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Whatsapp'),
    Envelop: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Envelop'),
    Carousel: () => import(/* webpackChunkName: "Carousel" */ '@/components/Carousel/Carousel')
  },
  data: () => ({
    carouselBannerOptions: {
      type: 'loop',
      autoplay: true,
      arrows: false,
      perPage: 2,
      speed: 1000,
      interval: 5000,
      gap: '2rem',
      lazyLoad: 'nearby',
      breakpoints: {
        767: {
          perPage: 1
        }
      }
    },
    usp: [
      {
        title: 'Lebih Praktis',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Contact'),
        description: 'Tes Psikologi SIM di EDU TEST dilakukan menggunakan Web, Tablet atau  handphone, tidak perlu pergi kemana-mana'
      },
      {
        title: 'Kemudahan Pembayaran',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Integration'),
        description: 'Kami menerima berbagai jenis pembayaran yang memudahkan Anda'
      },
      {
        title: 'Mitra Resmi POLRI',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Car'),
        description: 'EDU TEST adalah platform Tes Psikologi SIM mitra resmi Biro Psikologi SSDM POLRI dan Korlantas POLRI'
      }
    ],
    process: [
      {
        title: 'Registrasi Akun',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/UserAdd'),
        description: 'Akses aplikasi via mobile, tablet ataupun laptop, untuk melakukan registrasi di EDU TEST'
      },
      {
        title: 'Booking Test',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Book'),
        description: 'Lakukan booking test, pilih lokasi dan waktu yang telah disediakan'
      },
      {
        title: 'Lakukan Test',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/File'),
        description: 'Lakukan test psikologi online dari perangkat yang anda miliki'
      },
      {
        title: 'Dapatkan Hasil',
        icon: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/CheckMark'),
        description: 'Hasil test akan tersedia 1 jam setelah anda melakukan test, anda dapat mengunduh dalam format pdf'
      }
    ]
  }),
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  watch: {
    $route(val) {
      scrollToTheTarget(val.hash, -50)
      if (this.isMobile) this.$refs.headerMobile.close()
    }
  },
  mounted() {
    this.setupReveal()
  },
  methods: {
    setupReveal() {
      const observer = new IntersectionObserver(this.callback, {
        rootMargin: '0px 0px 0px 0px',
        threshold: 0
      })
      const animatedElement = document.querySelectorAll('.reveal')
      animatedElement.forEach((el) => {
        observer.observe(el)
      })
    },
    callback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible')
        } else {
          // entry.target.classList.remove('is-visible')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.hero {
  min-height: 700px;
  @media screen and (max-width: 767px) {
    min-height: 0;
  }
}
.reveal-right > * {
  opacity: 0;
  transition: transform 2s 0.25s cubic-bezier(0, 1, 0.3, 1), opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}
.reveal-top > * {
  opacity: 0;
  transition: transform 2s 0.25s cubic-bezier(0, 1, 0.3, 1), opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}
.is-visible > * {
  opacity: 1;
  transform: none;
}
.delay-0 > * {
  transition-delay: 0ms;
}
.delay-1 > * {
  transition-delay: 100ms;
}
.delay-2 > * {
  transition-delay: 200ms;
}
.delay-3 > * {
  transition-delay: 300ms;
}
</style>
